import { stAnalytics } from "@repo/analytics";
import { getRequestClient } from "@repo/client";
import { decodeBase64URI } from "@repo/encoding";
import { captureException } from "@repo/observability";
import { BrowserStorage } from "@repo/storage";
import { clsx } from "clsx";
import { type Component, Match, Show, Switch, createSignal, onCleanup, onMount } from "solid-js";
import { unwrap } from "solid-js/store";
import { CircularStatus } from "~/components/CircularStatus";
import { type AuthenticatedIdentity, isAuthenticatedIdentity } from "~/domains/identity/types";
import type { WebsocketSnapshot, WebsocketSnapshotExpanded } from "~/domains/ws/service";
import { useWire } from "~/wire";

export type FeedbackFormProps = {
  additionalMetadata?: Record<string, unknown>;
};

export const FeedbackForm: Component<FeedbackFormProps> = (props) => {
  const wire = useWire();
  const feedbackService = wire.services.feedback;
  const [isSubmitting, setIsSubmitting] = createSignal(false);
  const [isSubmitted, setIsSubmitted] = createSignal(false);

  const authenticatedEmailValue = () => {
    if (isAuthenticatedIdentity(wire.services.identity.snapshot.context.identity)) {
      const identity = wire.services.identity.snapshot.context.identity as AuthenticatedIdentity;
      BrowserStorage.setFeedbackEmail(identity.email);
      return identity.email;
    }
    return "";
  };

  const emptyIfGuest = (e: string) => (e === "guest@guest.storytell.ai" ? "" : e);
  const emailValue = () => {
    const authenticatedEmail = authenticatedEmailValue();
    if (authenticatedEmail) {
      return emptyIfGuest(authenticatedEmail);
    }
    return emptyIfGuest(BrowserStorage.getFeedbackEmail() ?? "");
  };

  let emailRef: HTMLInputElement;
  let feedbackRef: HTMLTextAreaElement;

  const onClose = () => {
    feedbackService.closeFeedbackPanel();
  };

  onMount(() => {
    document.addEventListener("keydown", (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    });
  });

  const decorateFeedbackWithSomeTechnicals = (feedback: string) => {
    const threeTicks = "```";
    return `${feedback}
${threeTicks}
Authenticated: ${isAuthenticatedIdentity(wire.services.identity.snapshot.context.identity)}
Location: ${window.location.href}
User Agent: ${navigator.userAgent}
${threeTicks}`;
  };

  const onSubmit = async (e: Event) => {
    e.preventDefault();
    setIsSubmitting(true);

    const feedback = feedbackRef.value ?? "No feedback was provided";
    let email = emailRef.value ?? "No user provided email";
    let displayName = "Anonymous";

    if (!authenticatedEmailValue()) {
      BrowserStorage.setFeedbackEmail(email);
    }

    if (isAuthenticatedIdentity(wire.services.identity.snapshot.context.identity)) {
      const identity = wire.services.identity.snapshot.context.identity as AuthenticatedIdentity;
      email = `${email} [primaryEmail=${identity.email}]`;
      displayName = identity.displayName;
    }

    const expandWebsocketSnapshot = (snapshot: WebsocketSnapshot) => {
      if (snapshot.context.envelopes.length === 0) {
        return snapshot;
      }
      const result = snapshot as WebsocketSnapshotExpanded;
      if (!result || !result.context || !result.context.envelopes) {
        return snapshot;
      }
      for (let i = 0; i < result.context.envelopes.length; i++) {
        const envelope = result.context.envelopes[i];
        if (!envelope) continue;
        if (envelope.data) {
          try {
            const dataExpanded = JSON.parse(decodeBase64URI(envelope.data));
            // envelope.data = "decoded";
            envelope.dataExpanded = dataExpanded;
            if (dataExpanded.messageContent) {
              dataExpanded.messageContentExpanded = JSON.parse(decodeBase64URI(dataExpanded.messageContent));
            }
          } catch (error) {
            envelope.dataExpanded = {
              error: error,
              comment: "unable to decode envelope data",
            };
          } finally {
            result.context.envelopes[i] = envelope;
          }
        }
      }
      return result;
    };

    const data = JSON.stringify({
      displayName,
      email,
      feedback: feedback,
      diagnostics: {
        environment: unwrap(wire.services.environment.settings),
        warnings: unwrap(wire.services.environment.warnings()),
        exceptions: unwrap(wire.services.environment.exceptions()),
        authMachine: unwrap(wire.services.identity.snapshot),
        threadMachine: (() => {
          const snapshot = unwrap(wire.services.threads.snapshot);
          const context = Object.assign({}, snapshot.context, {
            editorRef: snapshot.context.editorRef ? "exists" : "null",
          });
          return Object.assign({}, snapshot, { context });
        })(),
        websocketMachine: expandWebsocketSnapshot(unwrap(wire.services.websocket.snapshot)),
        experiments: unwrap(wire.services.experiments.data()),
        additionalMetadata: props.additionalMetadata ? unwrap(props.additionalMetadata) : "None",
        browser: {
          userAgent: navigator.userAgent,
          language: navigator.language,
          screenResolution: `${screen.width}x${screen.height}`,
          colorDepth: screen.colorDepth,
          pixelRatio: window.devicePixelRatio,
          cookieEnabled: navigator.cookieEnabled,
          doNotTrack: navigator.doNotTrack,
          online: navigator.onLine,
          hardwareConcurrency: navigator.hardwareConcurrency || null,
          currentLocation: window.location.href,
        },
      },
    });

    let diagnosticsURL = "Unavailable";
    try {
      const d = await getRequestClient(wire.services.identity.getIdentityToken).controlplane.SaveDiagnostics({ data });
      if (d?.data?.url) {
        diagnosticsURL = d.data.url;
      }
    } catch (error) {
      diagnosticsURL = JSON.stringify(error);
    }

    const body = {
      email,
      feedback: decorateFeedbackWithSomeTechnicals(feedback),
      displayName,
      diagnostics: diagnosticsURL,
    };

    try {
      const response = await fetch("/api/feedback", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (!response.ok) {
        throw new Error(`Failed to submit feedback: ${response.statusText}`);
      }
      setIsSubmitted(true);
    } catch (error) {
      alert(`Error submitting feedback: ${error}`);
      captureException(error);
      stAnalytics.track("error_submitting_feedback", { error });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Show when={feedbackService.isFeedbackPanelOpen()}>
      {/* biome-ignore lint/a11y/useSemanticElements: Panel does not appear when using dialog */}
      <div id="feedback-modal" class="relative z-max" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
        <div
          onClick={onClose}
          class={clsx("fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity", {
            "opacity-100 ease-out duration-300": feedbackService.isFeedbackPanelOpen(),
            "opacity-0 ease-in duration-200": !feedbackService.isFeedbackPanelOpen(),
          })}
          aria-hidden="true"
        />

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div
              class={clsx(
                "relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6",
                {
                  "ease-out duration-300 opacity-100 translate-y-0 sm:scale-100": feedbackService.isFeedbackPanelOpen(),
                  "ease-in duration-200 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95":
                    !feedbackService.isFeedbackPanelOpen(),
                },
              )}
            >
              <div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3 class="text-base font-semibold leading-6 text-gray-900 text-left" id="modal-title">
                    Share your feedback
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500 text-left">
                      Thanks for letting us know what's going on: bugs, what you'd like to see, or anything else! Share
                      your email so we can followup if needed.
                    </p>
                  </div>
                </div>
              </div>
              <Switch>
                <Match when={isSubmitted()}>
                  <div class="mt-5 sm:mt-6 sm:gap-3">
                    <div class="justify-center w-full inline-flex my-2">
                      Feedback submitted. Thank you for taking the time.
                    </div>
                    <button
                      onClick={onClose}
                      type="button"
                      class="w-full inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Close
                    </button>
                  </div>
                </Match>
                <Match when={true}>
                  <div class="mt-5 sm:mt-6 sm:gap-3">
                    <div>
                      <input
                        ref={(ref) => {
                          emailRef = ref;
                        }}
                        type="email"
                        name="email"
                        id="email"
                        value={emailValue()}
                        class="block w-full rounded-md border-0 py-1.5 px-2 my-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="you@example.com"
                      />
                      <textarea
                        ref={(ref) => {
                          feedbackRef = ref;
                        }}
                        id="about"
                        placeholder="Describe what you're experiencing"
                        name="about"
                        rows="8"
                        value={feedbackService.feedbackContent()}
                        class="px-2 py-2 my-2 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                    <Show
                      fallback={
                        <div class="justify-center w-full inline-flex my-2">
                          <span class="pt-1">
                            <SubmittingStatus />
                          </span>
                          <span class="text-sm px-2 py-0">Submitting...</span>
                        </div>
                      }
                      when={!isSubmitting()}
                    >
                      <div class="flex items-center">
                        <button
                          onClick={onSubmit}
                          type="submit"
                          class="w-2/3 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Submit
                        </button>
                        <button
                          onClick={onClose}
                          type="button"
                          class="w-1/3 ml-4 inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold text-gray-800 hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-800"
                        >
                          Close
                        </button>
                      </div>
                    </Show>
                  </div>
                </Match>
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </Show>
  );
};

const SubmittingStatus = () => {
  const [progress, setProgress] = createSignal(0);
  onMount(() => {
    const interval = setInterval(() => {
      const next = progress() + 10;
      setProgress(next > 100 ? 0 : next);
    }, 50);
    onCleanup(() => clearInterval(interval));
  });

  return <CircularStatus progress={progress()} title={"Submitting..."} />;
};
